import React, { useEffect } from 'react';
import { InputLabelProps } from '@mui/material/InputLabel/InputLabel';
import moment from 'moment/moment';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextFieldProps
} from '@mui/material';
import { isArray, isObject } from 'lodash';
import { useTableContext } from './TableContextProvider';
import {
  SelectInputField,
  SelectInputOption
} from 'src/common/select-input-field/SelectInputField';
import { useAppContext } from 'src/common/app-context-provider/AppContext';
import {
  AsyncAutocompletePropsV2,
  AsyncAutocompleteV2
} from 'src/common/async-autocomplete-v2/AsyncAutocompleteV2';
import {
  getDtoDateStringFromDate,
  isValidDate as checkIsValidDate,
  DATE_TIME_FORMAT,
  getAllFromPaginatedApiV2,
  parseIntSafely
} from 'src/lib';
import { GetAPI } from 'src/types';
import { Cancel } from '@mui/icons-material';
import { TextFieldV2 } from 'src/common/text-field-v2/TextFieldV2';

const FILTER_ITEM_SEPARATOR = ',';

export interface SelectOptionChangedHelpers {
  filters: Record<string, string>;
  setFilter: (filterName: string, filterValue?: string) => void;
  setFilters: (newfilters: Record<string, string | undefined>) => void;
}

export type TableFilterSelectComponentPropsV2 = {
  filterType: FilterType.SINGLE_SELECT;
  filterName: string;
  label: string;
  options: SelectInputOption[];
  comparator?: (a: SelectInputOption, b: SelectInputOption) => number;
};

export type TableFilterMultiSelectComponentPropsV2 = {
  filterType: FilterType.MULTI_SELECT;
  filterName: string;
  label: string;
  options: SelectInputOption[];
};

export type TableFilterAutocompleteComponentPropsV2<T> = {
  filterType: FilterType.ASYNC_AUTOCOMPLETE;
  multiple?: boolean;
  filterName: string;
  label: string;
  getOptionId: (option: T) => number;
  getOptions: GetAPI<T>;
  onSelectedOptionChanged?: (
    value: T | T[] | null,
    helpers: SelectOptionChangedHelpers,
    reason?: AutocompleteChangeReason,
    detail?: AutocompleteChangeDetails<T> | undefined
  ) => void;
} & Omit<
  AsyncAutocompletePropsV2<T>,
  'value' | 'getOptions' | 'onSelectedOptionChanged'
>;

export type TableFilterInputComponentPropsV2 = {
  filterType: FilterType.TEXT;
  filterName: string;
  label: string;
  type?: string;
  InputLabelProps?: InputLabelProps;
} & TextFieldProps;

export enum FilterType {
  SINGLE_SELECT,
  TEXT,
  MULTI_SELECT,
  ASYNC_AUTOCOMPLETE
}

export type TableFilterPropsV2<T> =
  | TableFilterSelectComponentPropsV2
  | TableFilterMultiSelectComponentPropsV2
  | TableFilterInputComponentPropsV2
  | TableFilterAutocompleteComponentPropsV2<T>;

export const TableFilterV2 = <T,>(props: TableFilterPropsV2<T>) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { filters, setFilter, setFilters, handlePageChange } = useTableContext();
  const { handleError } = useAppContext();

  /**
   *  State for {@link ASYNC_AUTOCOMPLETE} filter type
   *
   *  {@link isQueryStringProcessed} {@link true} if objects of type {@link T} whose
   *  ids set in the query string as filters have been fetched from the backend and
   *  assigned to {@link autocompleteValues}. {@link false} otherwise.
   *
   *  {@link autocompleteValues} objects of type {@link T} that have been selected.
   *
   *  {@link isAutocompleteDisabled} initially set to {@link false } set to {@link true}
   *  until {@link isQueryStringProcessed} is set to {@link true}. Is used to ensure
   *  that the drop-down filter is disabled until the frontend has fetched the
   *  ids set in the query string (before then, the frontend does not have any appropriate
   *  text to use as labels for the selected options).
   **/
  const isQueryStringProcessed = React.useRef<boolean>(false);
  const [autocompleteValues, setAutocompleteValues] = React.useState<
    T[] | undefined
  >();
  const [isAutocompleteDisabled, setIsAutocompleteDisabled] =
    React.useState(true);

  useEffect(() => {
    if (isQueryStringProcessed.current) {
      return;
    }

    if (props.filterType === FilterType.ASYNC_AUTOCOMPLETE) {
      isQueryStringProcessed.current = true;

      const { filterName, getOptions } = props;
      const valueString = filters[String(filterName)];
      if (!valueString) {
        setIsAutocompleteDisabled(false);
        return;
      }

      getAllFromPaginatedApiV2({
        getApi: getOptions,
        filters: { id: valueString }
      })
        .then((result) => {
          setAutocompleteValues(result);
          setIsAutocompleteDisabled(false);
        })
        .catch(handleError);
    } else {
      isQueryStringProcessed.current = true;
    }
  }, [filters, handleError, props]);

  switch (props.filterType) {
    case FilterType.SINGLE_SELECT: {
      const { filterName, comparator, options, ...componentProps } = props;

      const selectAllFilterOption: SelectInputOption = {
        label: 'Clear Filter',
        key: ''
      };

      return (
        <SelectInputField
          {...componentProps}
          id={filterName}
          value={filters[String(filterName)] || ''}
          onChange={(event: { target: { value: string | undefined } }) => {
            handlePageChange(0);
            setFilter(filterName, event.target.value);
          }}
          options={options}
          headerOption={selectAllFilterOption}
          size="medium"
        />
      );
    }

    case FilterType.TEXT: {
      const { filterName, ...componentProps } = props;
      const isDateInputComponent = props.type === 'date';
      const filterValue = filters[String(filterName)] || '';

      return (
        <TextFieldV2
          {...componentProps}
          id={filterName}
          size="medium"
          value={
            isDateInputComponent
              ? moment(filterValue).format(
                  DATE_TIME_FORMAT.FORMIK_DATE_FIELD_FORMAT
                )
              : filterValue
          }
          onChange={(event: { target: { value: string | undefined } }) => {
            const rawValueNew = event.target.value;

            if (isDateInputComponent && rawValueNew === '') {
              handlePageChange(0);
              setFilter(filterName, '');
              return;
            }

            if (isDateInputComponent) {
              const date = moment(
                rawValueNew,
                DATE_TIME_FORMAT.FORMIK_DATE_FIELD_FORMAT
              ).toDate();
              const isValidDate = checkIsValidDate(date);
              const filterValueNew = isValidDate
                ? getDtoDateStringFromDate(date, true)
                : '';
              isValidDate && handlePageChange(0); setFilter(filterName, filterValueNew);
              return;
            }
            handlePageChange(0);
            setFilter(filterName, rawValueNew);
          }}
        />
      );
    }

    case FilterType.MULTI_SELECT: {
      const { filterName, options, label } = props;

      const valueString = filters[String(filterName)];

      return (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select<string[]>
            value={valueString ? valueString.split(FILTER_ITEM_SEPARATOR) : []}
            label={label}
            multiple={true}
            open={isOpen}
            onClose={() => {
              setIsOpen(false);
            }}
            onOpen={() => {
              setIsOpen(true);
            }}
            onChange={(event) => {
              if (isArray(event.target.value)) {
                const filterValue = event.target.value.join(',');
                handlePageChange(0);
                setFilter(filterName, filterValue);
              } else {
                const filterValue = event.target.value;
                handlePageChange(0);
                setFilter(filterName, filterValue);
              }
              setIsOpen(false);
            }}
            renderValue={(selected) => {
              if (options.length === 0) {
                return <></>;
              }
              return (
                <Stack direction="column" spacing={2}>
                  {selected.map((value) => {
                    const selectedOption = options.find(
                      (option) => option.key.toString() === value
                    );
                    return (
                      <div>
                        <Chip
                          key={value}
                          label={selectedOption ? selectedOption.label : value}
                          deleteIcon={
                            <Cancel
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                          onDelete={(event) => {
                            event.stopPropagation();
                            const selectedIndex = selected.indexOf(value);
                            if (selectedIndex > -1) {
                              selected.splice(selectedIndex, 1);
                              handlePageChange(0);
                              setFilter(
                                filterName,
                                selected.join(',')
                              );
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </Stack>
              );
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.key} value={option.key.toString()}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    case FilterType.ASYNC_AUTOCOMPLETE: {
      const {
        filterName,
        label,
        multiple,
        getOptions,
        getOptionsFilters,
        getOptionId,
        getOptionLabel,
        isOptionEqualToValue,
        onSelectedOptionChanged
      } = props;

      const filterIdValue = parseIntSafely(filters[String(filterName)]);

      return (
        <AsyncAutocompleteV2<T>
          name={filterName}
          label={label}
          disabled={isAutocompleteDisabled}
          value={
            multiple
              ? autocompleteValues || []
              : filterIdValue
              ? autocompleteValues?.find(
                  (value) => getOptionId(value) === filterIdValue
                )
              : null
          }
          onSelectedOptionChanged={(value) => {
            if (isArray(value)) {
              setAutocompleteValues(value);
              const filterIds = value ? value.map(props.getOptionId) : [];
              handlePageChange(0);
              setFilter(
                filterName,
                filterIds.map((id) => id.toString()).join(FILTER_ITEM_SEPARATOR)
              );
            } else if (isObject(value)) {
              setAutocompleteValues([value]);
              const filterId = getOptionId(value);
              handlePageChange(0);
              setFilter(filterName, filterId.toString());
            } else {
              handlePageChange(0);
              setFilter(filterName, undefined);
              setAutocompleteValues([]);
            }
            onSelectedOptionChanged &&
              onSelectedOptionChanged(value, {
                filters,
                setFilter,
                setFilters
              });
          }}
          multiple={multiple}
          getOptions={getOptions}
          getOptionsFilters={getOptionsFilters}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqualToValue}
          component={AsyncAutocompleteV2}
        />
      );
    }
  }
};
