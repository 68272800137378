import React, { ChangeEvent } from 'react';
import { useTableContext } from 'src/common/table/components/TableContextProvider';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';

type TableSearchProps = {
  component: (props: {
    value?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  }) => JSX.Element;
  label: string;
  placeholder?: string;
};

export enum SearchableFieldPlaceholders {
  USERS = 'Name, Surname, etc...',
  TRAINING_SITE = 'Area Location, Room Code, etc...',
  THEME = 'Name, Module Code, etc...',
  THEME_SCHEDULE = 'Name, Theme Number, etc...',
  TAG = 'Name, etc...',
  SUPERVISOR = 'Name, Surname, etc...',
  STUDENT = 'Name, Surname, etc...',
  STAFF = 'Name, Surname, etc...',
  ROLE = 'Name, etc...',
  PROGRAMME = 'Name, Programme Code, etc...',
  PROGRAMME_SCHEDULE = 'Name, Programme Code, etc...',
  MODULE = 'Name, Module Code, etc...',
  MODULE_SCHEDULE = 'Name, Module Code, etc...',
  FACILITY = 'Name, Physical Address, etc...',
  CLASS_LIST = 'Name, Surname, etc...',
  CALENDAR_YEAR = 'Year, etc...',
  BOOKING = 'Title, Description, etc...',
  AREA_CAPACITY = 'Theme Name, Module Name, etc...',
  ACTIVITY_TYPE = 'Category, Type, etc...',
  ACTIVITY = 'Type, Description, Tag, etc...',
  FORM_CATEGORY = 'Title, etc...',
}

export const TableSearch = ({
  component,
  ...componentProps
}: TableSearchProps) => {
  const { search, setSearch, handlePageChange } = useTableContext();

  const Component = component;
  return (
    <ThemableBox
      type={ThemableBoxType.SEARCH_FIELD}
    >
      <Component
        {...componentProps}
        value={search || ''}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handlePageChange(0);
          setSearch(event.target.value);
        }}
      />
    </ThemableBox>
  );
};
