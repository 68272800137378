import { useCallback } from 'react';
import {
  FormCategoryDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';
import { FormCategory } from 'src/types/models/FormCategory.type';

export interface IFormCategoryService {
  getFormCategories: GetAPI<FormCategory>;
  toggleFormCategoryStatus: (
    formTypeId: number,
    active: boolean,
    abortController?: AbortController
  ) => Promise<void>;
  createFormCategory: (
    formCategory: FormCategory,
    abortController?: AbortController
  ) => Promise<void>;
  getFormCategory: (
    formCategoryId: number,
    abortController?: AbortController
  ) => Promise<FormCategory>;
  updateFormCategory: (
    formCategory: FormCategory,
    abortController?: AbortController
  ) => Promise<void>;
  deleteFormCategory: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
}

const FormCategoryStatusUrlParam = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const useFormCategoryService = (): IFormCategoryService => {
  const formCategoryApi = useAxiosInstance('/v2/formCategory');

  const getFormCategories = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<FormCategory>> => {
      return formCategoryApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<FormCategory>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<FormCategory>;
        });
    },
    [formCategoryApi]
  );

  const toggleFormCategoryStatus = useCallback(
    (
      formCategoryId: number,
      isActiveStatus: boolean,
      abortController?: AbortController
    ): Promise<void> =>
      formCategoryApi.put(
        `update-status/${formCategoryId}/${
          isActiveStatus
            ? FormCategoryStatusUrlParam.ACTIVE
            : FormCategoryStatusUrlParam.INACTIVE
        }`,
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      ),
    [formCategoryApi]
  );

  const createFormCategory = useCallback(
    (
      formCategory: FormCategory,
      abortController?: AbortController
    ): Promise<void> => {
      return formCategoryApi.post(
        '',
        {
          ...getDTOfromFormCategory(formCategory)
        },
        {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [formCategoryApi]
  );

  const getFormCategory = useCallback(
    (
      formCategoryId: number,
      abortController?: AbortController
    ): Promise<FormCategory> => {
      return formCategoryApi
        .get(`${formCategoryId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => response.data as FormCategory);
    },
    [formCategoryApi]
  );

  const updateFormCategory = useCallback(
    (
      formCategory: FormCategory,
      abortController?: AbortController
    ): Promise<void> =>
      formCategoryApi.put(
        `${formCategory.id}`,
        getDTOfromFormCategory(formCategory),
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      ),

    [formCategoryApi]
  );

  const deleteFormCategory = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      formCategoryApi.delete(`${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),

    [formCategoryApi]
  );

  return {
    getFormCategory,
    createFormCategory,
    getFormCategories,
    toggleFormCategoryStatus,
    updateFormCategory,
    deleteFormCategory
  };
};

function getDTOfromFormCategory(formCategory: FormCategory): FormCategoryDTO {
  return {
    ...formCategory,
    academicYears: formCategory.academicYears.map((year) => ({
      id: year.id,
      academicYear: year.displayName
    }))
  };
}
